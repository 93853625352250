import React from "react";
import PropTypes from "prop-types";

import styles from "./alert.module.scss";

export default function Alert({ value }) {
  const formatMessage = (message) => {
    if (typeof message === "string" || typeof message === "number")
      return message;
    return JSON.stringify(message);
  };

  return <div className={styles.alert}>{formatMessage(value)}</div>;
}

Alert.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

Alert.defaultProps = {
  value: "",
};
