// Fetch forecast data
export const FETCH_FORECAST_DATA_REQUEST = "FETCH_FORECAST_DATA_REQUEST";
export const FETCH_FORECAST_DATA_SUCCESS = "FETCH_FORECAST_DATA_SUCCESS";
export const FETCH_FORECAST_DATA_FAILURE = "FETCH_FORECAST_DATA_FAILURE";

// User location
export const SET_USER_LOCATION = "SET_USER_LOCATION";

// Notifications
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATIONS";
